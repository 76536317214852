import {
  Button,
  Column,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react';
import './ParameterTable.scss';

import { ArrowRight } from '@carbon/react/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SurveyViewData } from '../../types/SurveyViewData';
import i18n from '../../i18n';
import { useOidc } from '@axa-fr/react-oidc';

const ParameterTable = (props: { id: string; data: SurveyViewData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, logout, renewTokens, isAuthenticated } = useOidc();

  useEffect(() => {
    console.log('🐱', props.data);
  }, []);

  const handleSubmitClick = () => {
    if (isAuthenticated || !props.data.oidc_required) {
      // If the user is already authenticated, navigate directly
      navigate(`/submission?surveyId=${props.id}`, { state: props.data });
    } else {
      // Store the intended survey ID and state in local storage before authentication
      localStorage.setItem('surveyId', props.id);
      localStorage.setItem('surveyData', JSON.stringify(props.data));
      
      // Trigger login
      login().then(() => {
        // Retrieve the survey ID and state from local storage after successful login
        const storedSurveyId = localStorage.getItem('surveyId');
        const storedSurveyDataString = localStorage.getItem('surveyData');
  
        // Check if the retrieved items are not null
        if (storedSurveyId && storedSurveyDataString) {
          const storedSurveyData = JSON.parse(storedSurveyDataString);
          // Navigate to the submission page with the stored state
          navigate(`/?surveyId=${storedSurveyId}`, { state: storedSurveyData });
        } else {
          // Handle the case where the expected data is not found in local storage
          console.error('No stored survey data found after login.');
        }
  
        // Clean up local storage
        localStorage.removeItem('surveyId');
        localStorage.removeItem('surveyData');
      }).catch(error => {
        console.error('Login failed:', error);
      });
    }
  };
  

  const hasItemNumber = Boolean(props.data.item_nrs);
  const hasSupplierItemNumber = Boolean(props.data.supplier_item_nrs);

  return (
    <>
      <Column lg={14} md={6} sm={4} className="status-table-left">
        <Table aria-label="survey table">
          <TableHead>
            <TableRow>
              {hasItemNumber && <TableHeader>{t('common.parameterTable.itemNumber')}</TableHeader>}
              {hasSupplierItemNumber && <TableHeader>{t('common.parameterTable.supplierItemNumber')}</TableHeader>}
              <TableHeader>{t('common.parameterTable.CNNumber')}</TableHeader>
              <TableHeader>{t('common.parameterTable.CNName')}</TableHeader>
              <TableHeader>{t('common.parameterTable.dueDate')}</TableHeader>
              <TableHeader>{t('common.parameterTable.importerName')}</TableHeader>
              <TableHeader>{t('common.parameterTable.status')}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
            {hasItemNumber && <TableCell>{props.data.item_nrs}</TableCell>}
            {hasSupplierItemNumber && <TableCell>{props.data.supplier_item_nrs}</TableCell>}
              <TableCell>{props.data.cn_number}</TableCell>
              <TableCell>{props.data.cn_name}</TableCell>
              <TableCell>
                {props.data.due_date ? new Date(props.data.due_date).toLocaleDateString(i18n.language) : ''}
              </TableCell>
              <TableCell>{props.data.importer_name}</TableCell>
              <TableCell>{props.data.status}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Column>
      <Column lg={2} md={2} sm={4} className="status-table-right">
        <Button
          className="status-submit-btn"
          tooltipAlignment="start"
          onClick={handleSubmitClick}
          renderIcon={ArrowRight}
          // disabled if props.data.status not Sent, Resent or Started
          disabled={props.data.status !== 'Sent' && props.data.status !== 'Resent' && props.data.status !== 'Started' && props.data.status !== 'dummy_status'}
          type="submit"
        >
          {isAuthenticated || !props.data.oidc_required ? t('common.buttons.submitSurveyData'): t('common.buttons.verifyOIDC')}
          
        </Button>
      </Column>
    </>
  );
};

export default ParameterTable;
