import { Button, ButtonSet, Column, Form, Grid, TextInput,   Accordion,
  AccordionItem} from '@carbon/react';
import { useTranslation } from 'react-i18next';

const FormStep5 = ({ formState, handleChange, prevStep, nextStep }: any) => {
  const { t } = useTranslation();
  interface EmissionParams {
    emission_type: string;
    FQ: number;
    NCV: number;
    EF: number;
    Cash: number;
    Ctotal: number;
    AD: number;
    OF: number;
    CF: number;
  }

  
  function calculateEmissions(emissionParams: { emission_type: string; FQ: number; NCV: number; EF: number; Cash: number; Ctotal: number; AD: any; OF: number; CF: number; }) {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emissionParams)
      };
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
      console.log('Request options:', requestOptions);
  
      fetch(`${backendUrl}/api/emissions/calculate_emissions`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        response.json().then(json => console.error(json));
        throw new Error('Network response was not ok.');
      })
      .then(data => {
        resolve(data.emissions); // Resolve with the emissions number
      })
      .catch(error => {
        console.error('Error calculating indirect emissions:', error);
        reject(error); // Reject the promise on error
      });
  });
}
  

 
const calcNextStep = async (event: { preventDefault: () => void; }) => {
  event.preventDefault(); // Prevent default form submission behavior
  if (formState.calculationMethod !== 'default') {
    nextStep(event); // Proceed to the next step after successful update
    const locationId = formState.country.location_id;
    console.log('Looking up emission factor for location ID:', locationId);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    
    try {
      const response = await fetch(`${backendUrl}/api/emissions/location/${locationId}`, {
        method: 'GET',
        headers: {
          'Accept': 'text/plain'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const emissionFactorString = await response.text();
      const emissionFactor = parseFloat(emissionFactorString);
      console.log('Emission factor for location ID:', emissionFactor);

      const emissionParams = {
        emission_type: 'electricity',
        FQ: 0,
        NCV: 0,
        EF: emissionFactor || 0,
        Cash: 0,
        Ctotal: 0,
        AD: formState.indirectEmission || 0,
        OF: 0,
        CF: 0
      };

      let emissionsData: any = await calculateEmissions(emissionParams);
      console.log('Electricity emissions calculated:', emissionsData);
      emissionsData += Number(formState.calculatedEmissionsIndirectPrecursors);
      console.log('Indirect emissions calculated:', emissionsData);

      handleChange({ name: 'calculatedEmissionsIndirect', value: emissionsData });;

      
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      // Handle the error (e.g., show an error message to the user)
    }
    
} else {
  nextStep(event);
}};
  


  return (
    <Form onSubmit={calcNextStep} id="step5" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
                <h4>{t('survey.step5.name')}</h4>
              </Column>
              {formState.calculationMethod !== 'default' &&(
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="indirectEmission"
                id="indirectEmission"
                labelText={t('survey.step5.indirectEmission.labelText')}
                helperText={t('survey.step5.indirectEmission.helperText')}
                invalidText={t('survey.step5.indirectEmission.invalidText')}
                placeholder={t('survey.step5.indirectEmission.placeholder')}
                value={formState.indirectEmission}
                onChange={handleChange}
              ></TextInput>
            </Column>
              )}
            {formState.calculationMethod === 'default' &&(
            <Column lg={8} md={4} sm={4}>
              <p> You chose to use default values. The indirect emissions have been calculated for you. <br/><br/> Please move on to the next step.</p>
            </Column>
              )}
          </Grid>
        </div>
        <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h5 className="faq">{t('survey.step5.faq.title')}</h5>
                <Accordion>
                  <AccordionItem title={t('survey.step5.faq.accordion1.title')}>
                    <p>{t('survey.step5.faq.accordion1.text')}</p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step5.faq.accordion2.title')}>
                    <p>
                      {t('survey.step5.faq.accordion2.text')}
                      <div>
                        <iframe
                          src="https://share.synthesia.io/embeds/videos/9f3126fc-f7ad-4bb8-bdac-0025f5fe8616"
                          loading="lazy"
                          title="CORA Context Help - Indirect Emissions"
                          allow="encrypted-media; fullscreen;"
                        ></iframe>
                      </div>
                    </p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step5.faq.accordion3.title')}>
                    <p>{t('survey.step5.faq.accordion3.text')}</p>
                  </AccordionItem>
                </Accordion>
              </Column>
            </Grid>
          </div>
      </div>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep5;
