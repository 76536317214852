
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Grid,
  Column,
  Modal,
  Search,
 } from '@carbon/react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import './CbamAcademy.scss';
import greenFactory from '../../assets/img/factory.png';
import improvedFactory from '../../assets/img/improved_factory.png';
import React, { useState, useRef, useEffect } from 'react';
import { InfoSection, InfoCard, VideoCard } from '../../components/Info/Info';
import { WatsonHealthAiStatusInProgress } from '@carbon/icons-react';
import SearchFunction from '../../components/Search/Search';
import faqsData from '../../data/Faqs';




const CbamAcademy = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);


 
  return (

    <Grid className="landing-page" fullWidth>
<Column lg={16} md={8} sm={4} className="landing-page__banner">
  <Breadcrumb noTrailingSlash>
    <BreadcrumbItem>
      <a href="/">Getting started</a>
    </BreadcrumbItem>
  </Breadcrumb>
  <h1 className="landing-page__heading">CBAM Academy</h1>
</Column>
<Column lg={16} md={8} sm={4} className="landing-page__r2">
  <Tabs defaultSelectedIndex={0}>
    <TabList className="tabs-group">
      <Tab>Introduction to CBAM</Tab>
      <Tab>Using CORA</Tab>
      <Tab>FAQ & Support</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
        <Grid className="tabs-group-content">
          <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
            <h2 className="landing-page__subheading">What is CBAM?</h2>
            <p className="landing-page__p">
            With the Carbon Border Adjustment Mechanism (CBAM), the EU has introduced a set of rules from October 2023 that will force importers of various key raw materials and intermediate products to take action. From the last quarter of 2023, this border adjustment mechanism provides for a reporting obligation for companies that import goods with a high carbon footprint from non-EU countries.
            </p>
            <Button onClick={() => setOpen(true)}>Watch a short video about CBAM</Button>
            <Modal open={open} onRequestClose={() => setOpen(false)} passiveModal modalHeading="Introduction to CBAM & CORA" primaryButtonText="Close Window">
            <p style={{
        marginBottom: '1rem'
      }}>
 <iframe className="modalVideo" src="https://share.synthesia.io/embeds/videos/930963af-4d28-4100-afa8-2aef55be4185" loading="lazy" title="Synthesia video player - CORA Context Help - Welcome" allow="encrypted-media; fullscreen;"></iframe>
        </p>

  </Modal>
          </Column>
          <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
  <img className="landing-page__illo"
    src={greenFactory}
    alt="Carbon illustration"
    width={367}
    height={350}
  />
</Column>
        </Grid>
      </TabPanel>
      <TabPanel>
      <Grid className="tabs-group-content">
          <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
            <h2 className="landing-page__subheading">What is CORA?</h2>
            <p className="landing-page__p">
            CORA, also known as Carbon Optimizer & Reporting Assistent was designed to help you answer your suppliers' CBAM survey as accurately as possible while saving you time and starting in 2026 money. This tool is absolutely free for you and we recommend to use the CBAM Academy to tackle this regulation.
            </p>
            <Button onClick={() => setOpen(true)}>Watch the CORA Survey Walkthrough</Button>
            <Modal open={open} onRequestClose={() => setOpen(false)} passiveModal modalHeading="Introduction to CBAM & CORA" primaryButtonText="Close Window">
        <p style={{ marginBottom: '1rem' }}>
          <iframe  className="modalVideo" loading="lazy"></iframe>
        </p>
      </Modal>
          </Column>
          <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
  <img className="landing-page__illo"
    src={improvedFactory}
    alt="Carbon illustration"
    width={450}
    height={405}
  />
</Column>
        </Grid>
      </TabPanel>
      <TabPanel>
        <Grid className="tabs-group-content">
          <Column lg={16} md={8} sm={4} className="landing-page__tab-content">
          <InfoSection heading="Frequently Asked Questions" className="landing-page__r3">
        </InfoSection>       
          </Column>
          <Column lg={16} md={8} sm={4}>
          <SearchFunction details={faqsData}/>
        </Column>
        </Grid>
      </TabPanel>
    </TabPanels>
  </Tabs>
</Column>
<Column lg={16} md={8} sm={4} className="landing-page__r3">
<InfoSection heading="The Principles" className="landing-page__r3">
  <InfoCard
    heading="CORA is Open"
    body="Once you've filled out your survey, you will send the results directly to your supplier while you have access to your results. Export to the official EU Communication template is coming soon."
    
  />
  <InfoCard
    heading="CORA is Modular"
    body="CBAM can be overwhelming. We will only ask you what your supplier really needs to generate a compliant report and nothing more. Complexity will rise in sync with your CBAM skills thanks to CORA."
   
  />
  <InfoCard
    heading="CORA is Safe"
    body="At any step of the way, you'll have total control over your data. While Excel-based solutions often transmit your total production, CORA will use the values only for the necessary calculations."
  
  />
</InfoSection>
</Column>
<Column lg={16} md={8} sm={4} className="shortVideoBox">

<h3>Short Tutorials</h3> 
<p>Browse through all of CORA's tutorial videos to finish your CBAM survey.</p>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Reporting Period</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster1.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Reporting%20Period.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>All data to be entered is based on CBAM's 12 month-long reporting period</p>
  </div>
</div>

</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Direct Emissions</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster2.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Direct%20Emissions.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>How to calculate your direct emissions according to CBAM</p>
  </div>
</div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Indirect Emissions</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster3.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Indirect%20Emissions.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>How to calculate your indirect emissions according to CBAM - simplified by CORA</p>
  </div>
</div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Emission Calculation</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster4.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Emission%20Calculation.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>Overview of emission calculations according to CBAM & which data to prepare</p>
  </div>
</div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Activity Levels</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster5.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Activity%20Levels.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>Learn why we need your production output & why we'll not share it with your vendor.</p>
  </div>
</div>

</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Aggregated Goods Category</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster6.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Aggregated%20Goods%20Category.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>Aggregated Goods Categories explained.</p>
  </div>
</div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Carbon Price</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster7.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Carbon%20Price.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>Why the optional fields of Carbon Pricing might be worth filling out for you.</p>
  </div>
</div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Reporting Parameters</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster8.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Additional%20Reporting%20Parameters.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>How to fill out the optional fields Additional Reporting Parameters</p>
  </div>
</div>
</Column>
  </Grid>
  );
};

export default CbamAcademy;
