import { Theme } from '@carbon/react';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './layouts/Layout';

// design collab
import { MenuPoint } from './types/MenuPoint';

import './App.scss';
import NoMatchPage from './pages/NoMatchPage/NoMatchPage';
import SurveyPage from './pages/SurveyPage/SurveyPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import CbamAcademy from './pages/CbamAcademy/CbamAcademy';
import SBOM from './pages/Legal/SBOM';
import { SurveyViewData } from './types/SurveyViewData';

// OIDC
import { OidcProvider } from '@axa-fr/react-oidc';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const surveyId = queryParams.get('surveyId');
  const testCN = queryParams.get('testCN');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const menuPoints: MenuPoint[] = [];
  const [surveyDataLoading, setSurveyDataLoading] = useState(false);

  const [currentTheme, setCurrentTheme] = useState('g10');
  const [activePage, setActivePage] = useState(0);
  const [survey, setSurvey] = useState<SurveyViewData>();
  const [oidcConfig, setOidcConfig] = useState<{ 
    client_id: string, 
    redirect_uri: string, 
    silent_redirect_uri: string, 
    scope: string, 
    authority: string, 
    service_worker_relative_url: string, 
    service_worker_only: boolean, 
    demonstrating_proof_of_possession: boolean 
  } | null>(null);
  var myHeaders = new Headers();
  myHeaders.append('accept', 'application/json');
  function fetchOIDCConfig(surveyId: string) {
    console.log("Fetching OIDC config for survey ID:", surveyId);
    setSurveyDataLoading(true);
    fetch(`${backendUrl}/api/surveys/oidc/${surveyId}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((config) => {
        setOidcConfig({
          client_id: config.client_id,
          redirect_uri: config.redirect_uri || window.location.origin + "/authentication/callback",
          silent_redirect_uri: config.silent_redirect_uri || window.location.origin + "/authentication/silent-callback",
          scope: config.scope,
          authority: config.authority,
          service_worker_relative_url: config.service_worker_relative_url,
          service_worker_only: config.service_worker_only,
          demonstrating_proof_of_possession: config.demonstrating_proof_of_possession,
        });
        setSurveyDataLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching OIDC config:", error);
      });
  }


  // get survey by id
  useEffect(() => {
    // Define headers for fetching survey data
    var myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
  
    // Define requestOptions for fetch call
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
  
    if (surveyId) {
      setSurveyDataLoading(true);
      // Fetch survey by ID if surveyId is present
      console.log("BackendURL: ", backendUrl)
      fetch(`${backendUrl}/api/surveys/${surveyId}`, requestOptions)
        .then((response) => response.json())
        .then((data: SurveyViewData) => {
          setSurvey(data);
          console.log('database - surveydata', data);
          if (!data.oidc_required) {
            setSurveyDataLoading(false);
          }    
          })
        .catch((error) => console.log('error', error));
        
    } else if (testCN) {
      setSurveyDataLoading(true);
      // Use dummy data and the testCN if surveyId is not present but testCN is
      fetch(`${backendUrl}/api/surveys/agc/${testCN}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const testAGC = data.agc;
        console.log('database - agc', data);
        const dummyData : SurveyViewData = {
          id: '4f2869e6-9e96-4e3d-9a63-5b52d45c4103',
          cn_number: testCN !== null ? testCN : '',
          status: 'dummy_status',
          supplier_id: 'dummy_supplier_id',
          supplier_name: 'Dummy Supplier',
          item_nrs: 'dummy_item_nrs',
          supplier_item_nrs: 'dummy_supplier_item_nrs',
          aggregated_goods_category : testAGC,
          oidc_required: false
        };
    
        setSurvey(dummyData);
        console.log('using dummy data - surveydata', dummyData);
        setSurveyDataLoading(false);
      })
      .catch((error) => console.log('error', error));
    } else {
      // Handle the case where both surveyId and testCN are missing
      setSurveyDataLoading(false);
      console.error('Error: Both surveyId and testCN are missing. Unable to fetch or generate survey data.');
    }
  }, [surveyId, testCN]); // Add both surveyId and testCN as dependencies

  useEffect(() => {
    console.log(survey?.oidc_required, "survey oidc required");
    if (survey?.oidc_required) {
      console.log("Fetching OIDC config for survey", surveyId);
      fetchOIDCConfig(survey?.id);
    } else {
      console.log("OIDC config not required for survey", surveyId);
      
    }
  } , [survey]);

  useEffect(() => {
    if (oidcConfig) {
      // Update configuration object with fetched data
      const updatedConfiguration = {
        client_id: oidcConfig.client_id,
        redirect_uri: oidcConfig.redirect_uri || window.location.origin + "/authentication/callback",
        silent_redirect_uri: oidcConfig.silent_redirect_uri || window.location.origin + "/authentication/silent-callback",
        scope: oidcConfig.scope,
        authority: oidcConfig.authority,
        service_worker_relative_url: oidcConfig.service_worker_relative_url,
        service_worker_only: oidcConfig.service_worker_only,
        demonstrating_proof_of_possession: oidcConfig.demonstrating_proof_of_possession,
      };

      setConfiguration(updatedConfiguration);
    }
  }, [oidcConfig]);

  // Initial configuration with default values
  const [configuration, setConfiguration] = useState({
    client_id: "interactive.public.short",
    redirect_uri: window.location.origin + "/authentication/callback",
    silent_redirect_uri: window.location.origin + "/authentication/silent-callback",
    scope: "openid profile email api offline_access",
    authority: "https://demo.duendesoftware.com",
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: false,
    demonstrating_proof_of_possession: false,
  });

  return (
    <BrowserRouter>
    <OidcProvider configuration={configuration}>
      <Theme className="themeWrapper" theme={currentTheme}>
        <Layout
          menuPoints={menuPoints}
          setCurrentTheme={setCurrentTheme}
          appPrefix={survey?.supplier_name || ''}
          activePage={activePage}
          setActivePage={setActivePage}
        >
          <Routes>
            <Route path="/">
              <Route element={<WelcomePage survey={survey} surveyDataLoading={surveyDataLoading}/>} path="/" />
              <Route element={<SurveyPage />} path="submission" />
              <Route element={<NoMatchPage />} path="error" />
              <Route element={<CbamAcademy />} path="academy" />
              <Route element={<SBOM />} path="SBOM" />
            </Route>
          </Routes>
        </Layout>
      </Theme>
      </OidcProvider>
    </BrowserRouter>
  );
}

export default App;
