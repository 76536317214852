import {
  Button,
  ButtonSet,
  Column,
  Accordion,
  AccordionItem,
  Form,
  Grid,
  Tile,
  InlineNotification,
  UnorderedList,
  ListItem,
} from '@carbon/react';
import Masonry from 'react-masonry-css';
import {
  Industry,
  // Sustainability,
  Tag,
  WatsonHealthAiStatus,
  WatsonHealthImageAvailabilityLocal,
  Edit
} from '@carbon/react/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SustainabilitySVG from '../../../assets/img/sustainability.svg';
import '../FormMultisteps.scss';
import './FormStep9.scss';
import { MeterChart } from '@carbon/charts-react';
import "@carbon/charts/styles.css";
import { Tooltip } from '@carbon/react';
import { Information } from '@carbon/icons-react';

const FormStep9 = ({ formState, prevStep, handleSubmit }: any) => {
  const { t } = useTranslation();

  interface IEmission {
    category: string;
    emission_name: string;
    activity: string;
    unit: string;
    emission_id: number;
  }
  
  interface IFormState {
    directEmissions: IEmission[];
    calculatedEmissionsDirectPerTonne: number;
    route: { name: string; };
    // Add other necessary properties...
  }
  
  interface IFormStep9Props {
    formState: IFormState;
    prevStep: () => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  }

  const breakpointColumnsObj = {
    default: 4, // default number of columns
    2000: 3,
    1500: 2,
    900: 1
  };

    // Define categories for source streams with TypeScript typing
    const categories: Record<string, { name: string; items: IEmission[] }> = {
      'CBAM_PRFG': { name: 'Fuels & Gases', items: [] },
      'CBAM_PRRP': { name: 'Precursors', items: [] },
      'CBAM_PROI': { name: 'Other Inputs', items: [] }
    };
  
    // Populate categories with items from directEmissions
    formState.directEmissions.forEach((emission : IEmission) => {
      if (categories[emission.category]) {
        categories[emission.category].items.push(emission);
      }
    });
  
    // Generate JSX for each category, to be used in a tile item
    const sourceStreamContent = Object.entries(categories).map(([key, category]) => {
      if (category.items.length > 0) {
        return (

          <Accordion id={'tile-accordion'}>
          <AccordionItem  id={`accordion-${key}`} title={`${category.name} (${category.items.length})`}>

                  {category.items.map(item => (
                    <p style={{color: 'grey'}} key={item.emission_id}>
                      {`${item.emission_name} (${item.activity}${item.unit})`}
                    </p>
                  ))}

          </AccordionItem>
          </Accordion>
        );
      }
      return null;
    }).filter(Boolean);  // Filter out any nulls (categories without items)
  


  const tileData = [
    {
      chart: (
        <MeterChart
        data={[
          { group: t('survey.step9.directemissions.title'), value: formState.calculatedEmissionsDirectPerTonne },
          { group: t('survey.step9.indirectemissions.title'), value: formState.calculatedEmissionsIndirectPerTonne },
        ]}
        options={{
          title: 'Total Emissions',
          height: '130px',
          meter: {
            proportional: {
              total: formState.calculatedEmissionsTotalPerTonne,
              unit: 't CO2e / tp'
            }
          },
          color: {
            pairing: {
              option: 2
            }
          }
        }}
      ></ MeterChart>
      ),
    },    
    {
      title: "Installation Data",
      items: [
        { name: "Installation name", value: formState.installationName },
        { name: "Economic activity", value: formState.economicActivity },
        {
          name: "Address",
          value: (
            <>
             {`${formState.street} ${formState.streetNumber}`}<br />
             {`${formState.POBox}`} {`${formState.additionalLine}`} <br />
             {`${formState.postCode}`} {`${formState.city}`} <br />
             {`${formState.country.country_name}`}
            </>
          ),
        },
        {
          name: "Contact details",
          value: (
            <>
              {`${formState.representative}`} <br />
              {`${formState.telephone}`} <br />
              {`${formState.email}`}
            </>
          ),
        }
      ]
    },
    {
      title: "Direct Emissions",
      items: [
        { name: "Calculated emissions", value: (<> {`${formState.calculatedEmissionsDirectPerTonne}`} t CO2e / tp </>) },
        { name: "Production route", value: formState.route.name },
        { name: "Source streams", value: (
          <>{sourceStreamContent}</> 
        ), }
      ]
    },
    {
      title: "Indirect Emissions",
      items: [
        { name: "Calculated emissions", value: (<> {`${formState.calculatedEmissionsIndirectPerTonne}`} t CO2e / tp </>)},
        ...(formState.calculationMethod === 'calculated' ? [
        { name: "Energy usage", value: (<> {`${formState.indirectEmission}`} Mwh </>) }
      ] : []),
      ]
    },
    ...(
      formState.calculationMethod === 'calculated' ? [
    {
      title: "Activity Level",
      items: [
        { name: "Total production", value:(<> {`${formState.productionTotal}`} t </>)},
        { name: "Scrap", value:(<> {`${formState.productionScrap}`} t </>)},
        { name: "Production - Scrap", value:(<> {`${formState.productionCalc}`} t </>)},
      ]
      
    }
  ] : []),
  ...(
    formState.shareCoveredPrice ? [
    {
      title: "Carbon price",
      items: [
        { name: "Carbon price", value:(<> {`${formState.shareCoveredPrice}`} % </>)},
        { name: "Free allocation", value:(<> {`${formState.shareRebate}`} % </>)},
      ]
    }
  ] : []),
    {
      title: "Reporting Period",
      items: [
        { name: "Start Period", value: (<> {`${formState.start}`}  </>) },
        { name: "End Period", value: (<> {`${formState.end}`}  </>) }

      ]
    },
    {
      title: "Good information",
      items: [
        { name: "CN code", value: formState.CNNumber },
        {
          name: "CN name",
          value: (
            <Tooltip align="top" label={formState.CNName}>
              <div className="cn-name-tooltip">
                {formState.CNName.length > 15 ? `${formState.CNName.substring(0, 15)}...` : formState.CNName}
              </div>
            </Tooltip>
          ),
        },
        { name: "AGC", value: formState.AGC },
        { name: "Calculation method", value: formState.calculationMethod }
      ]
    },
    ...(
      formState.specificParameters && formState.specificParameters.length > 0 ? [
        {
          title: "Additional Reporting Parameters",
          items: formState.specificParameters.map((param: {mandatory: boolean, definition : string , name: string; value: any; unit: string; }) => ({
            name: 
            <>
                {param.name}{' '}
                <Tooltip align="bottom" label={param.definition}>

        <Information size={'12'} color='grey' />

    </Tooltip>
              </>
            ,
            value: `${param.value} ${param.unit !== 'text' ? param.unit : ''}`.trim()  // Ensure to handle cases where unit might not be defined
          }))
        }
      ] : []
    ),
  ];
  
  
  return (
    <>
      <Form onSubmit={handleSubmit} id="step9" className="form-step">
            <h3 className='form-step-title'>{t('survey.step9.name')}</h3>
            <InlineNotification 
              kind="info"
              hideCloseButton
              title={t('survey.step9.infoTitle')}
              subtitle={t('survey.step9.infoSubtitle')}
              />
           <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {tileData.map((tile, index) => (
            <Column lg={5} md={4} sm={4} key={index}>
              <Tile className="tile-container">
              <div className="expandable-tile-container">
              <div className="tile-header">
                <span className="tile-title">{tile.title}</span>
                
                {!tile.chart && <Button hasIconOnly renderIcon={Edit} iconDescription=
                
                // "Edit" 
                "Direct edit currently unavailable"
                
                onClick={() => {/* handle edit */}} size="md" kind="ghost"/>}
              </div>
        {tile.items && tile.items.map((item : any, idx : any) => (
          <div className="tile-item" key={idx}>
            <span className="tile-item-name">{item.name}</span>
            <span className="tile-item-value">{item.value}</span>
          </div>
        ))}
        {tile.chart && tile.chart}
      </div>
    </Tile>
            </Column>
          ))}
    </Masonry>
        <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="step-btn-set-container">
              <ButtonSet className="step-btn-set">
                <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                  {t('common.buttons.back')}
                </Button>
                <Button kind="primary" tabIndex={0} type="submit">
                  {t('common.buttons.submit')}
                </Button>
              </ButtonSet>
            </div>
          </Column>
        </Grid>
      </Form>
    </>
  );
};


export default FormStep9;
