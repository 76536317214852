import {
  Button,
  Checkbox,
  DataTable,
  Select,
  SelectItem,
  ComboBox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  NumberInput,
  IconButton,
  Modal,
  OverflowMenu,
  OverflowMenuItem
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { DataTableHeader } from '../../types/DynamicTable';
import { EmissionSource } from '../../types/SurveySupplierInput';
import { StarFilled, Edit, TrashCan, AddLarge} from '@carbon/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import './DynamicEmissionTable.scss';
import AGCGoodsTable from './AGCGoodsTable';

interface DynamicEmissionTableProps {
  ES: EmissionSource[];
  ESUpdate: React.Dispatch<React.SetStateAction<EmissionSource[]>>;
  PrecursorsList: EmissionSource[];
  FuelGasList: EmissionSource[];
  OtherInputList: EmissionSource[];
  hasAttemptedNextStep : any;
}

interface EmissionSourceItem {
  // Define the structure that matches your emission source items
  emission_name: string;
  // Include other properties that EmissionSource items have
}



const DynamicEmissionTable = ({ ES, ESUpdate, PrecursorsList,FuelGasList,OtherInputList, hasAttemptedNextStep}: DynamicEmissionTableProps) => {
  const { t } = useTranslation();
  console.log(PrecursorsList)

  const prevESRef = useRef<EmissionSource[]>([]);
  const [isItemSelected, setIsItemSelected] = useState(false);

  const updateCNselection = (selectedGood: any | null) => {
    // Ensure selectedGood is not null before accessing its properties
    if (selectedGood) {
      console.log("Updating CN selection", selectedGood);
      setIsItemSelected(true); // Set to true if we have a selection
      setNewEmissionFormData(prevFormData => ({
        ...prevFormData,
        cn_code: selectedGood.cn_code, // Now safe to access
        // Update other fields as necessary
      }));
    } else {
      setIsItemSelected(false); // No item selected
      // Handle the case where selectedGood is null
      // Possibly reset form data or set defaults
    }
  };
  

  useEffect(() => {
    // Function to calculate multiplier
    const calculateMultiplier = async (emissionId: number, category: string, emissionName?: string, cn_code? : number) => {
      if (emissionName){
      try {
        
          const formattedEmissionName = emissionName.replace(/\//g, "%252F");
          console.log(formattedEmissionName)
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/emissions/calculate_multiplier/${emissionId}/${category}/${formattedEmissionName}/${cn_code}`);
        const data = await response.json(); // Parse the response as JSON
        // Assuming 'data' is the JSON object { "direct": 0, "indirect": 0 }
        // Update the corresponding emission source with the fetched multiplier
        ESUpdate(prevES =>
          prevES.map(es => 
            es.emission_id === emissionId ? 
            { ...es, direct_multiplier: data.direct, indirect_multiplier: data.indirect } : es
          )
        );
        console.log("Adding direct and indirect multipliers to emission source", data.direct, data.indirect);
      } catch (error) {
        console.error('Error calculating multiplier:', error);
      }
    }

    };

    // Identify and calculate multiplier for new emission sources
    const newEmissions = ES.filter(es => !prevESRef.current.some(prevEs => prevEs.emission_id === es.emission_id));
    newEmissions.forEach(newEmission => calculateMultiplier(newEmission.emission_id, newEmission.category, newEmission.emission_name, newEmission.cn_code));

    // Update prevESRef with the current state for the next effect execution
    prevESRef.current = ES;
  }, [ES, ESUpdate]); // This effect depends on the current ES array and the update function

  const [isDangerModalOpen, setIsDangerModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [hasAttemptedAdd, setHasAttemptedAdd] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState<number | null>(null);
  const [isEmissionSourceSelected, setIsEmissionSourceSelected] = useState(false);
  const [newEmissionFormData, setNewEmissionFormData] = useState({
    category: 'CBAM_PRRP', // Default to 'Precursors' or your preferred default value
    mandatory: false,
    emission_name: '',
    emission_id: 0,
    activity: null,
    unit: 't', // Set a default unit if applicable
    purchased: true,
    cn_code: null
  });

  const [currentCategory, setCurrentCategory] = useState('');
  const [currentCategoryItems, setCurrentCategoryItems] = useState<EmissionSource[]>([]);
  const [AGCgoods, setAGCgoods] = useState([]);

  
  const handleEmissionNameChange = async (selectedItem: EmissionSource | null) => {
    if (selectedItem) {
      // Update form data with the selected item
      setNewEmissionFormData((prevFormData) => ({
        ...prevFormData,
        ...selectedItem,
        emission_id: selectedItem.emission_id,
      }));
  
      setIsEmissionSourceSelected(true); // Valid emission source is selected
      if (selectedItem.category === 'CBAM_PRRP') {
        // Perform the API call to fetch emission data
        try {
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          };
          // Make sure to replace `selectedItem.emission_name` with the appropriate property if necessary
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/goods/${selectedItem.emission_name}`, requestOptions);
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          setAGCgoods(data); // Update state with the fetched data
          console.log("⚾️ Selected item:", selectedItem);
        } catch (error) {
          console.error("Failed to fetch emission data", error);
          // Handle error (e.g., reset state, show error message)
          setAGCgoods([]); 
      }}
    } else {
      setIsEmissionSourceSelected(false); // No emission source is selected
    }
  };

  useEffect(() => {
    console.log("🥎 AGC goods", AGCgoods)
  }, [AGCgoods]);
  
  const handleDelete = (emissionIdToDelete: number) => {
    const item = ES.find((esi) => esi.emission_id === emissionIdToDelete);
    if (item && item.mandatory) {
      setSelectedForDeletion(emissionIdToDelete);
      setIsDangerModalOpen(true);
    } else {
      ESUpdate((prevES) => prevES.filter((esi) => esi.emission_id !== emissionIdToDelete));
    }
  };
  
  const handleRevertToPrevious = () => {
    setIsDangerModalOpen(false);
    setSelectedForDeletion(null);
  };
  
  const handleRemoval = () => {
    if (selectedForDeletion != null) {
      ESUpdate((prevES) => prevES.filter((esi) => esi.emission_id !== selectedForDeletion));
      setIsDangerModalOpen(false);
      setSelectedForDeletion(null);
    }
  };
  
  // Function to map category codes to display names using the translation files
  const getCategoryDisplayName = (categoryCode: string) => {
    const categoryDisplayNames: Record<string, string> = {
      'CBAM_PRRP': t('survey.step4.dynamicTable.categoryDisplayName.precursor'),
      'CBAM_PROI': t('survey.step4.dynamicTable.categoryDisplayName.otherInput'),
      'CBAM_PRFG': t('survey.step4.dynamicTable.categoryDisplayName.fuelGas'),
    };
    return categoryDisplayNames[categoryCode] || categoryCode; // Fallback to the code itself if no match is found
  };

  const unitList = [
    { id: 't', text: t('survey.step4.dynamicTable.unitText.tonne') },
    { id: 'kg', text: t('survey.step4.dynamicTable.unitText.kg') },
   // { id: 'm3', text: t('survey.step4.dynamicTable.unitText.m3') }, #TODO add m3 logic and import units from database
  ];

  const headers: DataTableHeader[] = [
    { key: 'mandatory', header: '', isSortable: false,}, 

    {
      key: 'emission_name',
      header: t('survey.step4.dynamicTable.header.emission_name'),
      isSortable: false,
    },
    {
      key: 'category', 
      header: t('survey.step4.dynamicTable.header.category'), 
      isSortable: false,
    },
    {
      key: 'activity',
      header: t('survey.step4.dynamicTable.header.activity'),
      isSortable: false,
    },
    {
      key: 'unit',
      header: t('survey.step4.dynamicTable.header.unit'),
      isSortable: false,
    },
    {
      key: 'purchased',
      header: t('survey.step4.dynamicTable.header.purchased'),
      isSortable: false,
    },
  ];

  const handleAddNew = (category: string) => {
    let items: EmissionSource[];
    switch (category) {
      case 'CBAM_PRRP':
        items = PrecursorsList;
        break;
      case 'CBAM_PRFG':
        items = FuelGasList;
        break;
      case 'CBAM_PROI':
        items = OtherInputList;
        break;
      default:
        items = []; // Or set a default
    }
  
    // Filter out already added items
    const filteredItems = items.filter(item => 
      !ES.find(esi => esi.emission_id === item.emission_id));
  
    setCurrentCategory(category);
    setCurrentCategoryItems(filteredItems); // Use filtered items here
  
    setNewEmissionFormData({
      ...newEmissionFormData,
      category: category,
      emission_name: '', // Reset the emission name since we're starting a new form
    });
    setHasAttemptedAdd(false)
    setIsAddModalOpen(true);
  };
  
  

  const handleChange = (emissionId: number, name: string, value: any) => {
    ESUpdate((prevES) =>
      prevES.map((item) => 
        item.emission_id === emissionId ? { ...item, [name]: value } : item
      )
    );
  };

  const cellComponent = (cell: any, index: number, row: EmissionSource) => {
    switch (cell[0]) {
      case 'emission_name':
        return (
          <span
            className="some-class-for-styling"
            id={`cell-text-${cell[0]}-${index}`}
          >
            {cell[1]}
          </span>
        );
        case 'category':
          return (
            <span
              className="some-class-for-styling"
              id={`cell-text-${cell[0]}-${index}`}
            >
              {getCategoryDisplayName(cell[1])} {/* Use the mapping function here */}
            </span>
          );
          case 'activity':
            return (
              <div className='activity-input-wrapper'>
              <NumberInput
                step={0.01}
                hideSteppers = {true}
                name="activity"
                id={`cell-textinput-activity-${row.emission_id}`}
                defaultValue={cell[1]}
                onChange={(event: { target: { value: any; }; }) => handleChange(row.emission_id!, "activity", event.target.value)}
                invalid= {hasAttemptedNextStep && (!cell[1] || cell[1] <= 0)}
              />
              </div>
              
            );
          case 'unit':
            return (
              <div className='unit-select-wrapper'>
              <Select
                id={`cell-select-unit-${row.emission_id}`}
                defaultValue={cell[1]}
                onChange={(event: { target: { value: any; }; }) => handleChange(row.emission_id!, "unit", event.target.value)}
                labelText=""
              >
                {unitList.map(unit => (
                  <SelectItem key={unit.id} value={unit.id} text={unit.text} />
                ))}
              </Select>
            </div>
            );
          case 'purchased':
            return (
              <Checkbox
                id={`cell-checkbox-purchased-${row.emission_id}`}
                checked={cell[1]}
                onChange={(event: { target: { checked: any; }; }) => handleChange(row.emission_id!, "purchased", event.target.checked)}
              />
            );
      default:
        return <></>;
    }
  };

  return (
    <>
      <DataTable rows={ES} headers={headers} >
        {() => {
          return (
            
            <TableContainer>
              <TableToolbar>
                <TableToolbarContent>


    <div className="primary-overflow-button">
    <OverflowMenu ariaLabel="add-overflow-menu" renderIcon={() => <AddLarge fill="white" />} /* Make sure the icon is white */ flipped size="lg" align="bottom">
      <OverflowMenuItem
        itemText="Add Precursor" requireTitle
        onClick={() => handleAddNew('CBAM_PRRP')}
      />
      <OverflowMenuItem
        itemText="Add Fuel/Gas" requireTitle
        onClick={() => handleAddNew('CBAM_PRFG')}
      />
      <OverflowMenuItem
        itemText="Add Other Input" requireTitle
        onClick={() => handleAddNew('CBAM_PROI')}
      />
    </OverflowMenu>
    </div>
    
  </TableToolbarContent>
              </TableToolbar>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableHeader
                          key={header.key}
                          isSortable={header.isSortable}
                          isSortHeader
                          //   onClick={() =>
                          //     handleChangeSort({
                          //       columnId: header.key,
                          //       direction: sortInfo.direction,
                          //     })
                          //   }
                          //   sortDirection={
                          //     sortInfo && header.key === sortInfo.columnId
                          //       ? sortInfo.direction
                          //       : SortDirection.UNSORTED
                          //   }
                        >
                          {header.header}
                        </TableHeader>
                      );
                    })}
                    <TableHeader
  key="actions-header"
  isSortable={false}
  colSpan="2" // Span across two columns for edit and delete actions
>
  {''}
</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody style={{ minHeight: '200px' }}>
  {ES.map((esi: EmissionSource, rowIndex) => {
    return (
      <TableRow key={`table-row-${esi.emission_name}-${esi.emission_id}`}>
      {/* Render the mandatory field with the CSS class */}
      <TableCell className="mandatory-field">
        {esi.mandatory && (
          <IconButton
            label="Required"
            kind="ghost"
            align="top"
          >
            <StarFilled style={{ cursor: 'pointer' }} />
          </IconButton>
        )}
      </TableCell>
      {/* Render other cells */}
      {headers.slice(1).map((header) => { // Exclude the first header (mandatory)
        const cellValue = esi[header.key as keyof EmissionSource];
        return (
          <TableCell key={`table-cell-${esi.emission_name}-${header.key}-${esi.emission_id}`}>
            {cellComponent([header.key, cellValue], rowIndex, esi)}
          </TableCell>
        );
      })}
        {/* Edit button */}
  <TableCell key={`edit-cell-${esi.emission_id}`} className="action-buttons">
    <IconButton
      label="Edit"
      kind="ghost"
      align="top"
      onClick={() => {/* Function to handle edit - to be implemented */}}
    >
      <Edit/>
    </IconButton>
  </TableCell>
  {/* Delete button */}
  <TableCell key={`delete-cell-${esi.emission_id}`} className="action-buttons">
    <IconButton
      kind="ghost"
      label="Delete"
      align="top"
      onClick={() => handleDelete(esi.emission_id)}
    >
        <TrashCan />
     </IconButton>
  </TableCell>
</TableRow>
    );
  })}
</TableBody>
              </Table>
            </TableContainer>
           
          );
        }}
      </DataTable>
      {isDangerModalOpen && (
      <Modal
        open={isDangerModalOpen}
        danger
        modalHeading={t('survey.step4.dangermodal.heading')}
        modalLabel={t('survey.step4.dangermodal.label')}
        primaryButtonText={t('survey.step4.dangermodal.primaryButtonText')}
        secondaryButtonText={t('survey.step4.dangermodal.secondaryButtonText')}
        onRequestClose={handleRevertToPrevious}
        onRequestSubmit={handleRemoval}
      >
        <p>{t('survey.step4.dangermodal.paragraph')}</p>
      </Modal>
    )}
    {isAddModalOpen && (
    <Modal 
      open={isAddModalOpen}
      modalHeading="Add New Emission Source"
      modalLabel="New Emission Source"
      primaryButtonText="Add"
      // primaryButtonDisabled={!isEmissionSourceSelected} // Disable the primary button if no emission source is selected
      secondaryButtonText="Cancel"
      onRequestClose={() =>  {
        setIsAddModalOpen(false);
        setIsEmissionSourceSelected(false); // Reset for the next use
        setAGCgoods([]); // Reset for the next use
      }}
      onRequestSubmit={() => {
        setHasAttemptedAdd(true)
        if (!isEmissionSourceSelected || (!isItemSelected && currentCategory === 'CBAM_PRRP')) {
          return; // Do not close the modal
        }
        
        ESUpdate((prevES) => [
          ...prevES,
          {
            ...newEmissionFormData,
          }
        ]);
        setIsAddModalOpen(false);
        setIsEmissionSourceSelected(false); // Reset for the next use
        setAGCgoods([]); // Reset for the next use
      }}
    >
     
    <ComboBox
        id="emission-name-dropdown"
        titleText="Emission Source"
        label="Choose an emission source"
        items={currentCategoryItems} // Use the state variable here
        itemToString={(item: any) => (item && item.emission_name ? item.emission_name : '')}
        onChange={(event: { selectedItem: EmissionSource; }) => handleEmissionNameChange(event.selectedItem)}
        invalid={!isEmissionSourceSelected && hasAttemptedAdd}
        invalidText="Please select an emission source"
      />
      <br/>
      {currentCategory === 'CBAM_PRRP'  && AGCgoods.length > 0 ? (

  <AGCGoodsTable AGCgoods={AGCgoods} onUpdateCNselection={updateCNselection} invalid={!isItemSelected && hasAttemptedAdd && isEmissionSourceSelected && currentCategory === 'CBAM_PRRP'}></AGCGoodsTable>

) : (
  <div style={{ height: '230px' }} /> // Inline styling for the spacer
)}
{!isItemSelected && hasAttemptedAdd && isEmissionSourceSelected && currentCategory === 'CBAM_PRRP' && (
  <div style={{ color: 'var(--cds-text-error, #da1e28)', marginTop: '5px', fontSize: 'var(--cds-label-01-font-size, 0.75rem)' }}>
    Please select a CN code for your emission source before proceeding.
  </div>
)}
</Modal>
)}

</>
  );
};

export default DynamicEmissionTable;
