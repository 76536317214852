import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Dropdown,
  Form,
  Grid,
  TextInput,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep8.scss';

const FormStep8 = ({ formState, handleChange, prevStep, nextStep }: any) => {
  const { t } = useTranslation();

  const cpSelector = [
    {
      id: 'cp1',
      text: 'Emissions Trading System',
    },
    {
      id: 'cp2',
      text: 'Tax or Levy',
    },
    {
      id: 'cp3',
      text: 'Combination',
    },
    {
      id: 'cp4',
      text: 'Other',
    },
    {
      id: 'cp5',
      text: 'None',
    },
  ];

  const rebateSelector = [
    {
      id: 'rs1',
      text: 'Free Allocation',
    },
    {
      id: 'rs2',
      text: 'Financial Compensation',
    },
    {
      id: 'rs3',
      text: 'Tax Deduction',
    },
    {
      id: 'rs4',
      text: 'Combination',
    },
    {
      id: 'rs5',
      text: 'Other',
    },
    {
      id: 'rs6',
      text: 'None',
    },
  ];

  const handleFormofCarbon = (event: any) => {
    const chosenCountry: FormStateAttribute = {
      name: 'formofCarbonPrice',
      value: event.selectedItem.text,
    };

    handleChange(chosenCountry);
  };

  const handleFormOfRebate = (event: any) => {
    const chosenCountry: FormStateAttribute = {
      name: 'formOfRebate',
      value: event.selectedItem.text,
    };

    handleChange(chosenCountry);
  };

  return (
    
    <Form onSubmit={nextStep} id="step8" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h4>{t('survey.step8.name')}</h4>
            </Column>
            <Column lg={8} md={4} sm={4}>
              <Dropdown
                options={cpSelector}
                name="formofCarbonPrice"
                id="formofCarbonPrice"
                initialSelectedItem={cpSelector[1]}
                items={cpSelector}
                itemToString={(item: any) => (item ? item.text : '')}
                onChange={handleFormofCarbon}
                labelText={t('survey.step8.formofCarbonPrice.labelText')}
                helperText={t('survey.step8.formofCarbonPrice.helperText')}
                invalidText={t('survey.step8.formofCarbonPrice.invalidText')}
                placeholder={t('survey.step8.formofCarbonPrice.placeholder')}
                // required
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <Dropdown
                options={rebateSelector}
                name="formOfRebate"
                id="formOfRebate"
                initialSelectedItem={rebateSelector[1]}
                items={rebateSelector}
                itemToString={(item: any) => (item ? item.text : '')}
                onChange={handleFormOfRebate}
                labelText={t('survey.step8.formOfRebate.labelText')}
                helperText={t('survey.step8.formOfRebate.helperText')}
                invalidText={t('survey.step8.formOfRebate.invalidText')}
                placeholder={t('survey.step8.formOfRebate.placeholder')}
                // required
              />
            </Column>

            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="shareCoveredPrice"
                id="shareCoveredPrice"
                labelText={t('survey.step8.shareCoveredPrice.labelText')}
                helperText={t('survey.step8.shareCoveredPrice.helperText')}
                invalidText={t('survey.step8.shareCoveredPrice.invalidText')}
                placeholder={t('survey.step8.shareCoveredPrice.placeholder')}
                // required
                value={formState.shareCoveredPrice}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="shareRebate"
                id="shareRebate"
                labelText={t('survey.step8.shareRebate.labelText')}
                helperText={t('survey.step8.shareRebate.helperText')}
                invalidText={t('survey.step8.shareRebate.invalidText')}
                placeholder={t('survey.step8.shareRebate.placeholder')}
                // required
                value={formState.shareRebate}
                onChange={handleChange}
              />
            </Column>
          </Grid>
        </div>
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h5 className="faq">{t('survey.step8.faq.title')}</h5>
              <Accordion>
                <AccordionItem title={t('survey.step8.faq.accordion1.title')}>
                  <p>{t('survey.step8.faq.accordion1.text')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p1')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p2')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p3')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p4')}</p>
                </AccordionItem>
                <AccordionItem title={t('survey.step8.faq.accordion2.title')}>
                  <p>
                    <div>
                      <iframe
                        src="https://share.synthesia.io/embeds/videos/ccd374b5-847d-4781-9227-aa5680e15265"
                        loading="lazy"
                        title="CORA Context Help - Carbon Price"
                        allow="encrypted-media; fullscreen;"
                      ></iframe>
                    </div>
                  </p>
                </AccordionItem>
                <AccordionItem title={t('survey.step8.faq.accordion3.title')}>
                  <p>{t('survey.step8.faq.accordion3.text')}</p>
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>
      </div>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep8;
