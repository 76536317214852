import {
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderSideNavItems,
  SideNav,
  SideNavItems,
  SkipToContent,
} from '@carbon/react';
import { Link } from 'react-router-dom';
import { MenuPoint } from '../../types/MenuPoint';
import { MvpRoute } from '../../types/MvpRoute';
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import './MvpHeader.scss';
import {Help} from '@carbon/icons-react';

interface MvpHeaderProps {
  menuPoints: MenuPoint[];
  prefix: string;
  title: string;
  setCurrentTheme: CallableFunction;
  activePage: number;
  setActivePage: CallableFunction;
}

const SWITCH_THEMES = ['white', 'g90'];

const MvpHeader = ({
  menuPoints,
  prefix,
  title,
  setCurrentTheme,
  activePage,
  setActivePage,
}: MvpHeaderProps) => {
  console.log('current active page:', activePage);

  const searchParams = new URLSearchParams(window.location.search);
  const surveyId = searchParams.get('surveyId');
  const testCN = searchParams.get('testCN');

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }: any) => (
        <Header aria-label="MVP" className="cora_header">
          <SkipToContent />
          <HeaderMenuButton
            className="header_button"
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName prefix={prefix}>{title}</HeaderName>
          <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false}>
            <SideNavItems>
              <HeaderSideNavItems>
                {menuPoints.map((menuPoint: MenuPoint, index: number) => {
                  return (
                    <HeaderMenu
                      aria-label={menuPoint.name}
                      menuLinkName={menuPoint.name}
                      key={index}
                    >
                      {menuPoint.routes.map((route: MvpRoute, indexRoute: number) => {
                        return route.isStartPage ? null : (
                          <HeaderMenuItem
                            element={Link}
                            to={route.path}
                            key={indexRoute}
                            onClick={() => {
                              setActivePage(route.id);
                            }}
                          >
                            {route.label}
                          </HeaderMenuItem>
                        );
                      })}
                    </HeaderMenu>
                  );
                })}
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>

          <HeaderGlobalBar>
            {/* New Support Button */}
  <HeaderGlobalAction 
     aria-label="Support" 
     onClick={() => window.open('/academy', '_blank')}
  >
    <Help size={20} style={{fill: 'white'}} />  
  </HeaderGlobalAction>
            <LanguageSwitcher />
            {console.log(typeof setCurrentTheme)}
            {/* <HeaderGlobalAction aria-label="Theme Switcher" tooltipAlignment="start">
              <BrightnessContrast
                size={20}
                onClick={() =>
                  setCurrentTheme((oldTheme: string) =>
                    oldTheme === SWITCH_THEMES[0] ? SWITCH_THEMES[1] : SWITCH_THEMES[0],
                  )
                }
              />
            </HeaderGlobalAction> */}
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default MvpHeader;
