import {
  Accordion,
  AccordionItem,
  Column,
  Grid,
  Loading,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowRight } from '@carbon/react/icons';

import ParameterTable from '../../components/ParameterTable/ParameterTable';
import { SurveyViewData } from '../../types/SurveyViewData';
import './WelcomePage.scss';
import { Row } from '@carbon/icons-react';
import { waitFor } from '@testing-library/react';
import ParameterTableSkeleton from '../../components/ParameterTable/ParameterTableSkeleton';
import { SkeletonText } from '@carbon/react';

const WelcomePage = ({ survey, surveyDataLoading }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;


  let [searchParams] = useSearchParams();
  let surveyId = searchParams.get('surveyId');
  let testCN = searchParams.get('testCN');
  // Check if either surveyId or testCN is missing and navigate to the error page
  useEffect(() => {
    if (!surveyId && !testCN) {
      navigate(`error`);
    }
  }, [searchParams, navigate]);
  
  if (surveyId != null && survey == null && surveyDataLoading == false) {
    window.location.reload();
  }

  return (
    <>
      <Grid>
        <Column lg={16} md={8} sm={4} className="survey-section">
          <h1>
            {survey ? 
              t('welcome.header', {
                importerName: survey['importer_name'],
              }) :
              <SkeletonText heading paragraph />
              }
          </h1>
        </Column>
      </Grid>

      <Grid>
        {survey && !surveyDataLoading ? <ParameterTable id={surveyId!} data={survey} /> : <> <ParameterTableSkeleton isLoading={true}/> <Loading withOverlay={true} /> </> }
  {/* to do: parameterize texts for translation */}
<Column lg={16} md={8} sm={4} className="academy_box">
  <div id="academyH1">CBAM Academy</div>
  <iframe className="videoDiv"src="https://share.synthesia.io/embeds/videos/930963af-4d28-4100-afa8-2aef55be4185" loading="lazy" title="Synthesia video player - CORA Context Help - Welcome" allow="encrypted-media; fullscreen;"></iframe>
 <div id="academyText">Learn how to fill out your CBAM survey with CORA</div>
 <div id="academyText"><Button className="status-submit-btn" renderIcon={ArrowRight} type="submit">Get Started</Button></div>
</Column>
<Column lg={16} md={8} sm={4} className="shortVideoBox">

<h3>Short Tutorials</h3> 
<p>Browse through all of CORA's tutorial videos to finish your CBAM survey.</p>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Reporting Period</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster1.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Reporting%20Period.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>All data to be entered is based on CBAM's 12 month-long reporting period</p>
  </div>
</div>

</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Direct Emissions</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster2.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Direct%20Emissions.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>How to calculate your direct emissions according to CBAM</p>
  </div>
</div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Indirect Emissions</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster3.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Indirect%20Emissions.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>How to calculate your indirect emissions according to CBAM - simplified by CORA</p>
  </div>
</div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
<h4>Emission Calculation</h4>  
<div className="video-container">
<video controls poster="http://cbam-online.com/videos/Poster4.png">
    <source src="http://cbam-online.com/videos/CORA%20Context%20Help%20-%20Emission%20Calculation.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>

  <div className="video-description">
    <p>Overview of emission calculations according to CBAM & which data to prepare</p>
  </div>
</div>
</Column>



      </Grid>
    </>
  );
};

export default WelcomePage;
