import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    Grid,
    Column,
    Modal,
    Search,
   } from '@carbon/react';
  import ReactDOM from 'react-dom';
  import { useTranslation } from 'react-i18next';
  import '../CbamAcademy/CbamAcademy.scss';
  import React, { useState, useRef, useEffect } from 'react';
  
  const SBOM = () => {
    const { t } = useTranslation();
    const [packages, setPackages] = useState<Package[]>([]);
   
    interface ExternalRef {
      referenceCategory: string;
      referenceType: string;
      referenceLocator: string;
    }
    
    interface Package {
      SPDXID: string;
      name: string;
      versionInfo: string;
      downloadLocation: string;
      filesAnalyzed: boolean;
      licenseConcluded?: string;
      supplier: string;
      externalRefs: ExternalRef[];
    }
    
    useEffect(() => {
      fetch('/sbom.json')
        .then((response) => response.json())
        .then((data) => {
          // Adjust this line to match the structure of your actual JSON data
          setPackages(data.packages);
        })
        .catch((error) => console.error('Error fetching SPDX data:', error));
    }, []);
   
    return (
  
      <Grid className="landing-page" fullWidth>
  <Column lg={16} md={8} sm={4} className="landing-page__banner">
    <h1 className="landing-page__heading">CORA Software Bill of Materials</h1>
    <p>Creation date and time: May 7th, 2024, 9:08 pm</p>
    <p>Author: Ventum Consulting GmbH & Co. KG, Creator: Generated by Github.com</p>
    <p>Generated by Github.com</p>
   
   
  </Column>
  <Column lg={16} md={8} sm={4} className="landing-page__r2">
  <table>
      <thead>
        <tr>
          <th>SPDX ID</th>
          <th>Name</th>
          <th>Version</th>
          <th>Download Location</th>
          <th>License Concluded</th>
          <th>Supplier</th>
        </tr>
      </thead>
      <tbody>
        {packages.map((pkg) => (
          <tr key={pkg.SPDXID}>
            <td>{pkg.SPDXID}</td>
            <td>{pkg.name}</td>
            <td>{pkg.versionInfo}</td>
            <td>{pkg.downloadLocation}</td>
            <td>{pkg.licenseConcluded}</td>
            <td>{pkg.supplier}</td>
          </tr>
        ))}
      </tbody>
    </table>


  </Column>
 
    </Grid>
    );
  };
  
  export default SBOM;
  