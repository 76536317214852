import { ProgressIndicator, ProgressStep } from '@carbon/react';
import { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SurveySupplierInput } from '../../types/SurveySupplierInput';
import './FormMultisteps.scss';
import FormStep1 from './FormStep1/FormStep1';
import FormStep2 from './FormStep2/FormStep2';
import FormStep3 from './FormStep3/FormStep3';
import FormStep4 from './FormStep4/FormStep4';
import FormStep5 from './FormStep5/FormStep5';
import FormStep6 from './FormStep6/FormStep6';
import FormStep7 from './FormStep7/FormStep7';
import FormStep8 from './FormStep8/FormStep8';
import FormStep9 from './FormStep9/FormStep9';
import { Column } from '@carbon/react';
import { Loading } from '@carbon/react';

export interface FormStateAttribute {
  name: string;
  value?: any;
}

// Helper function to compare states
function statesAreEqual(prevState: any, currentState: any) {
  return JSON.stringify(prevState) === JSON.stringify(currentState);
}

const FormMultisteps = (  { data, currentStep, setCurrentStep }: { data: SurveySupplierInput, currentStep : number , setCurrentStep: (step: number) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formState, setFormState] = useState<SurveySupplierInput>(data);
  const [isVertical, setIsVertical] = useState(window.innerWidth < 600); // Initial check for vertical layout

  useEffect(() => {
    function handleResize() {
      setIsVertical(window.innerWidth < 600); // Update based on screen width
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Check if the formState has been initialized
    if (!formState.id) {
      // Initialize the formState with the data prop
        setFormState(data);
      console.log('data maybe??', data);
    }
  }, [data, formState.id]); // Add formState.id as a dependency to check its initialization state
  
  

  const prevFormStateRef = useRef<SurveySupplierInput>();

  const fetchDraftData = async (surveyId: string) => {
    try {
      if (surveyId) {
        const result = await fetch(`${backendUrl}/api/surveys/${surveyId}/draft`, { method: 'GET'});
        const resultData = await result.json();
  
        return resultData;
      }
    } catch(e){
      console.log('error', e);
    }
  }
  
  const saveDraftData = async (surveyId: string) => {
    try {
      if (surveyId) {
        await fetch(`${backendUrl}/api/surveys/${surveyId}/draft`, {
          method: 'POST',
          body: JSON.stringify({ step_counter: currentStep, survey_data: JSON.stringify(formState) }),
          headers: { 'Content-Type': 'application/json'},
        })
      }
    } catch(e){
      console.log('error', e);
    }
  }

  useEffect(() => {
    console.log('Component mounted or data.id changed');

    const fetchData = async () => {
      console.log('Fetching data for survey:', data?.id);
      if(data?.id) {
        setLoading(true);
        const draftData = await fetchDraftData(data?.id);

        if(draftData && draftData.step_counter) {
          setCurrentStep(draftData.step_counter);
        }

        const initialState =  draftData?.survey_data ? JSON.parse(draftData.survey_data) : data;
        console.log('Initial state from storage or data:', initialState);
        if (currentStep !== 9) {
          setFormState(initialState);
        }
        prevFormStateRef.current = initialState;
        setLoading(false);
      }
    };

    fetchData();
  }, [data]);
  
  useEffect(() => {
    console.log('formState updated:', formState);

    const saveData = async () => {
      if (prevFormStateRef.current && !statesAreEqual(prevFormStateRef.current, formState)) {
        console.log('Detected change in state. Previous:', prevFormStateRef.current, ' Current:', formState);
        await saveDraftData(data?.id);
  
        prevFormStateRef.current = formState; // Consider moving this line outside the condition
      }

    }

    saveData();
  }, [currentStep, backendUrl]);
  

  const handleChange = (event: { target: { name: any; value: any; }; name?: any; value?: any; }) => {
    if (event.target) {
      setFormState(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value || null
      }));
    } else if (event.name) {
      setFormState(prevState => ({
        ...prevState,
        [event.name]: event.value
      }));
    }
  };

  const handleDiscard = () => {
    navigate(`/?surveyId=${formState.id}`);
  };

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    setShowConfetti(true);
    event.preventDefault();
    if (formState.status !== "dummy_status") {
      var myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Content-Type', 'application/json');
      var requestOptions = {
        method: 'POST',
        body: JSON.stringify({ survey_data: JSON.stringify(formState) }),
        headers: myHeaders,
      };
      fetch(`${backendUrl}/api/surveys/${data.id}/submit`, requestOptions)
        .then(response => response.json())
        .then(() => navigate(`/?surveyId=${formState.id}`))
        .catch(error => console.log('error', error));
    } else {
      navigate(`/?testCN=${formState.CNNumber}`);
      console.log('not submitting since this is a test survey');
    }
  };

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const renderFormStep = () => {
    switch (currentStep) {
      case 1: return <FormStep1 formState={formState} handleDiscard={handleDiscard} handleChange={handleChange} nextStep={nextStep} />;
      case 2: return <FormStep2 formState={formState} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />;
      case 3: return <FormStep3 formState={formState} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />;
      case 4: return <FormStep4 formState={formState} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />;
      case 5: return <FormStep5 formState={formState} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />;
      case 6: return <FormStep6 formState={formState} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />;
      case 7: return <FormStep7 formState={formState} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />;
      case 8: return <FormStep8 formState={formState} handleChange={handleChange} prevStep={prevStep} nextStep={nextStep} />;
      case 9: return <FormStep9 formState={formState} prevStep={prevStep} handleSubmit={handleSubmit} />;
      default: return <></>;
    }
  };

  if (loading) 
    return <Loading withOverlay={true} /> 

  return (
    <>

      {currentStep !== 9 ? (
                <>
                <Column lg={16} md={8} sm={4} className="survey-section">
                <h1>
                  {t('survey.header', {
                    CNNumber: formState.CNNumber,
                    AGC: formState.AGC,
                  })}
                </h1>
              </Column>
              </>): () => {}}
      <div className="container">
        {currentStep !== 9 && (
           <ProgressIndicator className="cora-progress-indicator" spaceEqually={true} vertical={isVertical}>
           <ProgressStep
             current={currentStep === 1}
             complete={currentStep > 1}
             label={t('survey.step1.name')}
             description={t('survey.step1.description')}
           />
           <ProgressStep
             current={currentStep === 2}
             complete={currentStep > 2}
             label={t('survey.step2.name')}
             description={t('survey.step2.description')}
           />
           <ProgressStep
             current={currentStep === 3}
             complete={currentStep > 3}
             label={t('survey.step3.name')}
             description={t('survey.step3.description')}
           />
           <ProgressStep
             current={currentStep === 4}
             complete={currentStep > 4}
             label={t('survey.step4.name')}
             description={t('survey.step4.description')}
           />
           <ProgressStep
             current={currentStep === 5}
             complete={currentStep > 5}
             label={t('survey.step5.name')}
             description={t('survey.step5.description')}
           />
           <ProgressStep
             current={currentStep === 6}
             complete={currentStep > 6}
             label={t('survey.step6.name')}
             description={t('survey.step6.description')}
           />
           <ProgressStep
             current={currentStep === 7}
             complete={currentStep > 7}
             label={t('survey.step7.name')}
             description={t('survey.step7.description')}
           />
           <ProgressStep
             current={currentStep === 8}
             complete={currentStep > 8}
             label={t('survey.step8.name')}
             description={t('survey.step8.description')}
           />

         </ProgressIndicator>
        )}
        {currentStep !== 9 ? (

        <div className={`cora-form-container`}>
          {renderFormStep()}
        </div>
        ) : (
          <div className={'cora-form-container-step9'}>
          {renderFormStep()}
        </div>
        )}


        {showConfetti && (
          <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={100} gravity={0.2}
                    initialVelocityX={5} initialVelocityY={-5} recycle={false}
                    style={{ position: 'absolute', top: position.y, left: position.x }} />
        )}
      </div>
    </>
  );
};

export default FormMultisteps;