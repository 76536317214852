import { Button } from '@carbon/react';
import { useState } from 'react';
import i18n from '../../../i18n';
import './LanguageSwitcher.scss';
import gbFlag from '../../../assets/img/flags/great-britain.png';
import cnFlag from '../../../assets/img/flags/china.png';
import deFlag from '../../../assets/img/flags/germany.png'; 
import esFlag from '../../../assets/img/flags/Spain.png';
import frFlag from '../../../assets/img/flags/france.png';

const LanguageSwitcher = () => {
  const json_file = require('../../../locales/languages.json');
  const languageList = json_file.languages;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(languageList[0]);
  const resolveImage = (languageId) => {
    switch (languageId) {
      case 'en': return gbFlag;
      case 'cn': return cnFlag;
      case 'es': return esFlag;
      case 'fr': return frFlag;
      case 'de': return deFlag;
      default: return null; // Or a placeholder image
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    console.log('Item clicked:', item.id, ' resolvedLanguage:', i18n.resolvedLanguage);

    i18n.changeLanguage(item.id);
    setSelectedItem(item);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <Button kind="ghost" className="dropdown-toggle" onClick={toggleDropdown}>
        <img
            width="30"
            height="30"
            src={resolveImage(selectedItem.id)}
            alt={selectedItem.label}
          />
      </Button>
      {isOpen && (
        <div className="dropdown-menu">
          {languageList.map((item) => (
            <Button key={item.id} className="dropdown-item" onClick={() => handleItemClick(item)}>
              <img
            width="30"
            height="30"
            src={resolveImage(item.id)}
            alt={item.label}
          />
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
