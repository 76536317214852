import React, { ReactNode, useEffect, useState } from 'react';
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableSelectRow,
  Pagination,
  Search
} from '@carbon/react';
import './DynamicEmissionTable.scss';

interface AGCGood {
  cn_code: number;
  description: string;
}

interface AGCGoodsTableProps {
    AGCgoods: AGCGood[];
    onUpdateCNselection: (selectedGood: any) => void;
    invalid : boolean;
    children?: ReactNode; 
  }

interface TableRow {
  id: string;
  cn_code: number;
  description: string;
}

interface TableHeader {
  key: string;
  header: string;
}

const AGCGoodsTable = ({ AGCgoods, onUpdateCNselection, invalid }: AGCGoodsTableProps) => {
  const headers: TableHeader[] = [
    { key: 'cn_code', header: 'CN Code' },
    { key: 'description', header: 'Description' },
  ];
  const aas = invalid
  const [renderKey, setRenderKey] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  // Helper function to create tokens for CN codes
const createCNTokens = (cnCode: { toString: () => any; }) => {
  const codeStr = cnCode.toString();
  const tokens = [];
  if (codeStr.length >= 2) tokens.push(codeStr.substring(0, 2));
  if (codeStr.length >= 3) tokens.push(codeStr.substring(0, 3));
  if (codeStr.length >= 4) tokens.push(codeStr.substring(0, 4));
  if (codeStr.length >= 5) tokens.push(codeStr.substring(0, 5));
  if (codeStr.length >= 6) tokens.push(codeStr.substring(0, 6));
  if (codeStr.length >= 6) tokens.push(codeStr.substring(0, 7));
  if (codeStr.length >= 8) tokens.push(codeStr.substring(0, 8));
  tokens.push(codeStr); // Add the full CN code as a token
  return tokens;
};

// Modified tokenize function that also accepts a CN code
const tokenize = (input: string, cnCode: number) => {
  const tokens = input.toLowerCase().match(/\b(\w+|%)|\b\d+\b/g) || [];
  return [...tokens, ...createCNTokens(cnCode)];
};

// Inside your filter logic:
const filteredGoods = AGCgoods.filter(good => {
  const searchTokens = tokenize(searchQuery, good.cn_code); // Include CN code tokens in search
  const goodTokens = tokenize(`${good.cn_code} ${good.description}`, good.cn_code); // Include CN code tokens in good tokens

  // Check if all tokens from the search query are found in the good tokens
  return searchTokens.every(token => goodTokens.includes(token));
});


  // Increment renderKey whenever AGCgoods changes to force remounting the DataTable
  useEffect(() => {
    setRenderKey((prevKey: number) => prevKey + 1);
    setCurrentPage(1); // Reset to the first page
    setPageSize(5);
    setSearchQuery('');

  }, [AGCgoods]);

  const rows = filteredGoods.map((good, index) => ({
    id: `${index}`,
    cn_code: good.cn_code,
    description: good.description,
  }));

   // Calculate the rows to be displayed on the current page
   const firstRowIndex = (currentPage - 1) * pageSize;
   const currentRows = rows.slice(firstRowIndex, firstRowIndex + pageSize);
   const [selectedGood, setSelectedGood] = useState(null);

  const handleRadioSelect = (rowId: string) => {
    console.log('Selected row:', rows.find(row => row.id === rowId));
    const selectedCN = rows.find(row => row.id === rowId)
    if (selectedCN) {
      setSelectedGood(selectedCN as any);
      // Call the callback function passed via props
      onUpdateCNselection(selectedCN);
    }
  };
  useEffect(() => {
    if (filteredGoods.length > 0 && !selectedGood) {
      onUpdateCNselection(null);
    }
  }, [selectedGood, filteredGoods, onUpdateCNselection]);
  
;

  return (
    <div>
      <span>Please select a CN code from the table below</span>
      <br/>
      <br/>
    <Search
  labelText="Search"
  placeholder="Search by CN Code or Description..."
  value={searchQuery}
  onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSearchQuery(e.target.value)}
  size="lg" // Optional: Adjust the size as needed
  id="search-input" // A unique ID for the input
/>
<br/>

    <DataTable
      key={renderKey} // Use renderKey to force remount
      rows={currentRows}
      headers={headers}
      isSortable
      radio = {true}
      render={({ 
        rows, 
        headers, 
        getTableProps, 
        getHeaderProps, 
        getRowProps,
        getSelectionProps
      }: { 
        rows: any[], 
        headers: TableHeader[], 
        getTableProps: () => React.HTMLAttributes<HTMLTableElement>, 
        getHeaderProps: (props: { header: any }) => React.HTMLAttributes<HTMLTableCellElement>, 
        getRowProps: (props: { row: any }) => React.HTMLAttributes<HTMLTableRowElement>,
        getSelectionProps: (props: { row: any, onChange : any }) => React.HTMLAttributes<HTMLTableRowElement>,
        getSelection: (props: { row: any}) => React.HTMLAttributes<HTMLTableRowElement>
      }) => (
        <div   style={
          invalid
             ? { border: '2px solid var(--cds-support-error, #da1e28)' }
             : {}
         }>
      
    <TableContainer>
      <Table {...getTableProps()}>
        <TableHead>

          <TableRow>
            <th scope="col"></th> {/* Add a table header for the radio buttons */}
            {headers.map(header => (
              <TableHeader key={header.key} {...getHeaderProps({ header })}>
                {header.header}
              </TableHeader>
            ))}
          </TableRow>
          
        </TableHead>

        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id} {...getRowProps({ row })}>
                      
              <TableSelectRow {...getSelectionProps({
                row,
                onChange: () => handleRadioSelect(row.id),
              })} />
              {row.cells.map((cell: { id: React.Key | null | undefined; value: any; }) => (
                <TableCell key={cell.id}>{cell.value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Pagination
        key={renderKey} // Use renderKey to force remount
        totalItems={filteredGoods.length}
        pageSize={pageSize}
        pageSizes={[5, 10, 15, 20]}
        currentPage={currentPage}
        onChange={({ page, pageSize }: { page: number, pageSize: number }) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
      />
  </div>
  
)}
    />
    </div>
    
  );
};

export default AGCGoodsTable;
