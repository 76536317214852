import MvpHeader from '../components/MvpHeader/MvpHeader';
import { MenuPoint } from '../types/MenuPoint';
import './Layout.scss';

interface LayoutProps {
  children: React.ReactNode;
  menuPoints: MenuPoint[];
  setCurrentTheme: CallableFunction;
  appPrefix: string;
  activePage: number;
  setActivePage: CallableFunction;
}

export default function Layout({
  children,
  menuPoints,
  setCurrentTheme,
  appPrefix,
  activePage,
  setActivePage,
}: LayoutProps) {
  return (
    <div className="viewport">
      <MvpHeader
        menuPoints={menuPoints}
        prefix={appPrefix}
        title="CORA"
        setCurrentTheme={setCurrentTheme}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <div className="bodyWrapper">{children}</div>
    </div>
  );
}
