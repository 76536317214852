import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  TextInput,
  Dropdown,
  DropdownSkeleton,
  SkeletonText,
  DataTableSkeleton
} from '@carbon/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmissionSource, Route, EmissionCalcParams } from '../../../types/SurveySupplierInput';
import DynamicEmissionTable from '../../DynamicEmissionTable/DynamicEmissionTable';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep4.scss';
import { use } from 'i18next';
import { DataTableHeader } from '../../../types/DynamicTable';

const FormStep4 = ({ formState, handleChange, prevStep, nextStep }: any) => {
  const { t } = useTranslation();
  
  const [routeIsLoading, setRouteIsLoading] = useState(true);
  const [sourceStreamIsLoading, setSourceStreamIsLoading] = useState(false);  
  const [RouteListInvalid, setRouteListInvalid] = useState(true);
  const [hasAttemptedNextStep, setHasAttemptedNextStep] = useState(false);
  
  function calculateEmissions(emissionParams: EmissionCalcParams) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(emissionParams)
    };
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
    // Log the requestOptions to debug
    console.log('Request options:', requestOptions);
  
    fetch(`${backendUrl}/api/emissions/calculate_emissions`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        // Log the response to debug
        response.json().then(json => console.error(json));
        throw new Error('Network response was not ok.');
      })
      .then(data => {
        console.log('Emissions calculated:', data.emissions);
        // Handle the successful response here, e.g., updating the UI
      })
      .catch(error => {
        console.error('Error calculating emissions:', error);
        // Handle the error here
      });
  }
  

  // Define your Category and EmissionType types with all possible values
  type Category = 'CBAM_PRRP' | 'CBAM_PROI'| 'CBAM_PRFG';
  type EmissionType = 'process' | 'combustion';

  // Map categories to their corresponding emission types
  const categoryToEmissionTypeMap: Record<Category, EmissionType> = {
    'CBAM_PRRP': 'process',
    'CBAM_PROI': 'process',
    'CBAM_PRFG': 'combustion',
    // Add other mappings as necessary
  };

  // Function to get emission type from category
  const getEmissionTypeFromCategory = (category: string): EmissionType => {
    return categoryToEmissionTypeMap[category as Category] || 'DefaultEmissionType'; // Replace 'DefaultEmissionType' with your default value
  };

  const validateActivities = () => {
    let isValid = true;
    for (const source of finalEmissionSources) 
      if (!source.activity || source.activity <= 0) {
        isValid = false;
        break;
      }
    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setHasAttemptedNextStep(true);
  
    // Validate if a route has been chosen
    if (!chosenRoute) {
      setRouteListInvalid(true); // Display validation error
      return; // Stop execution if no route is chosen
    }

    const isValid = validateActivities();
    if (!isValid) {
      return; // Stop the form submission if not valid
    }
    setRouteListInvalid(false); // Proceed since a route is chosen
  
    try {
      let directEmissionsValue = null;
      let indirectEmissionsValue = null;
      nextStep(event);

      if (formState.calculationMethod === 'calculated')
      
      {
        // Manually calculate total direct emissions
        directEmissionsValue = formState.directEmissions.reduce((total: number, { activity, unit, direct_multiplier }: { activity: number; unit: string; direct_multiplier: number; }) => 
        total + ((unit === 'kg' ? activity / 1000 : activity) * (direct_multiplier || 0)), 0);

        indirectEmissionsValue = formState.directEmissions.reduce((total: number, { activity, unit, indirect_multiplier }: { activity: number; unit: string; indirect_multiplier: number; }) => 
        total + ((unit === 'kg' ? activity / 1000 : activity) * (indirect_multiplier || 0)), 0);
      
        // Update the form state with direct emissions
        handleChange({ name: 'calculatedEmissionsDirect', value: directEmissionsValue });
        handleChange({ name: 'calculatedEmissionsIndirectPrecursors', value: indirectEmissionsValue });
      } 

      else if (formState.calculationMethod === 'default')
      
      {
        // Call API to get total direct and indirect emissions for default calculation method
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/emissions/default/${formState.CNNumber}`, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        
        // Update the form state with direct and indirect emissions from the API response
        directEmissionsValue = data.direct;
        indirectEmissionsValue = data.indirect;
        handleChange({ name: 'calculatedEmissionsDirectPerTonne', value: directEmissionsValue });
        handleChange({ name: 'calculatedEmissionsIndirectPerTonne', value: indirectEmissionsValue });
      }

      console.log('Direct Emissions Value per Tonne:', directEmissionsValue);
      console.log('Indirect Emissions Value per Tonne:', indirectEmissionsValue);
      
    } catch (error) {
      console.error('Error during form submission:', error);
      // Handle errors appropriately here
    }
  };
  

  const headers: DataTableHeader[] = [
    { key: 'mandatory', header: '', isSortable: false,}, 

    {
      key: 'emission_name',
      header: t('survey.step4.dynamicTable.header.emission_name'),
      isSortable: false,
    },
    {
      key: 'category', 
      header: t('survey.step4.dynamicTable.header.category'), 
      isSortable: false,
    },
    {
      key: 'activity',
      header: t('survey.step4.dynamicTable.header.activity'),
      isSortable: false,
    },
    {
      key: 'unit',
      header: t('survey.step4.dynamicTable.header.unit'),
      isSortable: false,
    },
    {
      key: 'purchased',
      header: t('survey.step4.dynamicTable.header.purchased'),
      isSortable: false,
    },
  ];

  const [routesList, setRoutesList] = useState<Route[]>([]);
  const [chosenRoute, setChosenRoute] = useState<Route>();

  // Different types of Emission sources:
  const [precursorsList, setPrecursorsList] = useState<EmissionSource[]>([]);

  const [fuelGasList, setFuelGasList] = useState<EmissionSource[]>([]);

  const [otherInputList, setOtherInputList] = useState<EmissionSource[]>([]);

  // Summarize of all the chosen emission sources:
  const [finalEmissionSources, setFinalEmissionSources] = useState<EmissionSource[]>([]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const fillMissingValueWithDefaultData = (emission_list: EmissionSource[]) => {
    return emission_list.map((es: EmissionSource) => ({
      ...es,
      unit: es.unit !== undefined ? es.unit : 't',
      purchased: es.purchased !== undefined ? es.purchased : true,
    }));
  };
    // Ref to store the previous value of formState.directEmissions
  const [emissionsRestored, setEmissionsRestored] = useState(false);

useEffect(() => {
  // This code will run only once after the component mounts.
  console.log("This runs only once upon mounting.");
  if (!precursorsList || precursorsList.length === 0) {
    if (formState.route && formState.route.id) {
      setSourceStreamIsLoading(true);
      fetchSourceStreamsData(formState.route.id, false);
    }
  }
  
}, []); // The empty array means no dependencies, so the effect runs once on mount.

useEffect(() => {
  console.log("Restored?", emissionsRestored)
  // Only update if emissions haven't been restored yet and if there's a difference in the state
  if (!emissionsRestored && JSON.stringify(formState.directEmissions) !== JSON.stringify(finalEmissionSources) && formState.directEmissions.length > finalEmissionSources.length ) {
    if (formState.calculationMethod !== 'default') {
      console.log("Restoring formState.directEmissions", formState.directEmissions);
      if (formState.route && formState.route.id) {
        setSourceStreamIsLoading(true);
        fetchSourceStreamsData(formState.route.id, false);
      }
      setFinalEmissionSources(formState.directEmissions);
      setEmissionsRestored(true); // Prevent future updates within this session
  };

  }
}, [formState.directEmissions]);

  useEffect(() => {
    // If there is a chosen route when the component mounts or updates, it is valid
    if (formState.route) {
      setRouteListInvalid(false);
    }
  }); // This effect runs whenever chosenRoute changes
  
  // useEffect(() => {
  //   // Check if formState.route is defined and has an id and method is calculated
  //   if (formState.route && formState.route.id && formState.directEmissions && formState.calculationMethod === 'calculated') {
  //     fetchSourceStreamsData(formState.route.id, true);
  //     console.log("fetchSourceStreamsData true");
  //   } else {
  //     console.log("Condition not met for fetchSourceStreamsData");
  //   }
  // }, [formState.route]);
  

  useEffect(() => {
    // If formState.route is already set, use it to set the chosenRoute and validity
    if (formState.route) {
      setChosenRoute(formState.route);
      setRouteListInvalid(false);
      setRouteIsLoading(false); // Set loading to false as we already have the route
      fetchRoutes(); // This function will fetch the routes
    } else {
      // Only fetch routes if formState.route is not set
      setRouteIsLoading(true); // Start loading only if we need to fetch routes
      fetchRoutes(); // This function will fetch the routes
    }
  }, [formState.route]); // This effect runs on mount and whenever formState.route changes
  
  
  // Define fetchRoutes to use the latest chosenRoute if available
  const fetchRoutes = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      const response = await fetch(`${backendUrl}/api/surveys/routes?cn_number=${formState.CNNumber}`, requestOptions);
      const result = await response.json();
      console.log("fetchRoutes result", result);
      setRoutesList(result.route_list);
      // If there is a pre-selected route in formState, find it in the new routesList and set it
      if (formState.route) {
        // Explicitly define the type of 'route' parameter as 'Route'
        const matchingRoute = result.route_list.find((route: Route) => route.id === formState.route.id);
        if (matchingRoute) {
          setChosenRoute(matchingRoute);
        }
      }
    } catch (error) {
      console.error('Fetching routes failed', error);
    } finally {
      setRouteIsLoading(false); // Stop loading after fetch is complete or if there's an error
    }
  };

  
  const eliminateDuplicatedList = (duplicateList: EmissionSource[]) => {
    const uniqueList = Array.from(new Set(duplicateList.map((item) => item.emission_name)))
      .map((emission_name) => duplicateList.find((item) => item.emission_name === emission_name))
      .filter(Boolean) as EmissionSource[];

    return uniqueList;
  };


  useEffect(() => {
    handleDirectEmission(finalEmissionSources);
  }, [finalEmissionSources]);

  const fetchSourceStreamsData = (route_id: number, routeChange: boolean) => {
    var myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
  
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
  
    console.log("triggered as", routeChange);
    fetch(`${backendUrl}/api/surveys/source_streams?cn_number=${formState.CNNumber}&route_id=${route_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        let precursor_list = result.precursor_list;
        let fuel_gas_list = result.fuel_gas_list;
        let other_input_list = result.other_input_list;
        setPrecursorsList(precursor_list);
        setFuelGasList(fuel_gas_list);
        setOtherInputList(other_input_list);
  
        if (routeChange) {
          if (Array.isArray(precursor_list)) {
            let newMandatoryList = precursor_list
              .filter(item => item.mandatory)
              .map(item => fillMissingValueWithDefaultData([item])) // Assuming fillMissingValueWithDefaultData expects an array
              .flat(); // Flattens the array of arrays into a single array of objects
  
            console.log("New mandatory list:", newMandatoryList);
  
            // Update final emission sources
            let existingMandatoryIds = new Set(formState.directEmissions.filter((item: { mandatory: any; }) => item.mandatory).map((item: { emission_id: any; }) => item.emission_id));
  
            // Filter out old mandatory items that are not in the new mandatory list and add new ones
            let updatedFinalEmissionSources = formState.directEmissions.filter((item: { mandatory: any; emission_id: unknown; }) => !item.mandatory || existingMandatoryIds.has(item.emission_id));
            let addedMandatoryList = newMandatoryList.filter(item => !existingMandatoryIds.has(item.emission_id));
  
            setFinalEmissionSources([...updatedFinalEmissionSources, ...addedMandatoryList]);
          } else {
            console.error('precursor_list is not an array:', precursor_list);
          }
        } else {
          // If not a route change, just use the existing final emission sources
          setFinalEmissionSources(formState.directEmissions);
        }
  
        setSourceStreamIsLoading(false);
      })
      .catch(error => console.log('error', error));
  };
  
  

  const handleRoute = (event: any) => {
    const route: FormStateAttribute = {
      name: 'route',
      value: event.selectedItem,
    };
    setRouteListInvalid(false); // Reset validation state on change
    setChosenRoute(event.selectedItem);
    handleChange(route);
    if (formState.calculationMethod !== 'default') {
    setSourceStreamIsLoading(true);
    fetchSourceStreamsData(event.selectedItem.id, true);};
  };


  const handleDirectEmission = (emission_list: EmissionSource[]) => {
    const directEmission: FormStateAttribute = {
      name: 'directEmissions',
      value: emission_list,
    };
    if (formState.calculationMethod !== 'default') {
      handleChange(directEmission);};
    // when to trigger?
    // 1. creation
    // 2. update
    // 3. change of its value
  };


  return (
    <>
      <Form onSubmit={handleSubmit} id="step4" className="form-step">
        <div className="grid-container-element">
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h4>{t('survey.step4.name')}</h4>
              </Column>

              <Column lg={8} md={4} sm={4}>
                <TextInput
                  name="cnNumber"
                  id="cnNumber"
                  labelText={t('survey.step3.CNNumber.labelText')}
                  helperText={t('survey.step3.CNNumber.helperText')}
                  invalidText={t('survey.step3.CNNumber.invalidText')}
                  placeholder={t('survey.step3.CNNumber.placeholder')}
                  value={formState.CNNumber}
                  readOnly
                ></TextInput>
              </Column>

              {/* route */}
              <Column lg={8} md={4} sm={4}>
                {routeIsLoading ? (
                    <>
                  {/* Placeholder for the Dropdown title */}
                  <SkeletonText width="20%" />
                  <DropdownSkeleton />
                  </>
                ) : (
                  <Dropdown
                    id="route"
                    initialSelectedItem={routesList[0]}
                    titleText={t('survey.step4.route.titleText')}
                    helperText={t('survey.step4.route.helperText')}
                    invalidText={t('survey.step4.route.invalidText')}
                    label={t('survey.step4.route.labelText')}
                    selectedItem={(formState.route) ? chosenRoute : ''}
                    onChange={handleRoute}
                    invalid={hasAttemptedNextStep && RouteListInvalid}
                    items={routesList}
                    itemToString={(item: any) => (item ? item.name : '')}
                    />
                    )}
                  </Column>
  
              {/* Dynamic table according to the chosen emission_items in the previous dropdowns */}
              <Column lg={16} md={8} sm={4}>
    {formState.calculationMethod !== 'default' && !sourceStreamIsLoading && !RouteListInvalid &&(
    <DynamicEmissionTable
      ES={finalEmissionSources}
      ESUpdate={setFinalEmissionSources}
      PrecursorsList={precursorsList}
      FuelGasList={fuelGasList}
      OtherInputList={otherInputList}
      hasAttemptedNextStep={hasAttemptedNextStep}
    />
  )}
      {formState.calculationMethod !== 'default' && sourceStreamIsLoading && !routeIsLoading &&(
        
        <DataTableSkeleton headers={headers} aria-label="sample table"  rowCount={formState.directEmissions?.length || 1} showHeader={false}/>
  )}
</Column>
            </Grid>
          </div>
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h5 className="faq">{t('survey.step4.faq.title')}</h5>
                <Accordion>
                  <AccordionItem title={t('survey.step4.faq.accordion1.title')}>
                    <p>{t('survey.step4.faq.accordion1.text')}</p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step4.faq.accordion2.title')}>
                    <p>
                      {t('survey.step4.faq.accordion2.text')}
                      <div>
                        <iframe
                          src="https://share.synthesia.io/embeds/videos/62b38196-ebdb-4dd2-9606-d85b104a827d"
                          loading="lazy"
                          title="CORA Context Help - Direct Emissions"
                          allow="encrypted-media; fullscreen;"
                        ></iframe>
                      </div>
                    </p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step4.faq.accordion3.title')}>
                    <p>{t('survey.step4.faq.accordion3.text')}</p>
                  </AccordionItem>
                </Accordion>
              </Column>
            </Grid>
          </div>
        </div>
        <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="step-btn-set-container">
              <ButtonSet className="step-btn-set">
                <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                  {t('common.buttons.back')}
                </Button>
                <Button kind="primary" tabIndex={0} type="submit">
                  {t('common.buttons.next')}
                </Button>
              </ButtonSet>
            </div>
          </Column>
        </Grid>
      </Form>
    </>
  );
};

export default FormStep4;